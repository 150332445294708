import React, {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { Frequency } from '@amzn/taskscheduling-service';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OptionDefinition } from 'src/common/types/OptionDefinition';
import { SelectChangeEvent } from 'src/common/types/Events';
import { defaultSchedule, Schedule } from 'src/common/types';
import { FrequencyOption } from 'src/common/constants/frequency';
import { getSlicedAllocationTaskInput } from 'src/common/utils/task';
import { Environment } from 'src/common/constants/environment';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';
import { convertTime, getTimezoneOffset } from 'src/common/utils/date';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { Notification } from 'src/common/constants/Notification';
import ScheduleSection from 'src/cf-allocations/pages/create-cf-allocation-page/components/ScheduleSection';
import FormSection from 'src/cf-allocations/pages/create-cf-allocation-page/components/FormSection';
import { Methodology } from 'src/common/constants/Methodology';

const CreateCFAllocationPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedScenario, setSelectedScenario] =
    useState<OptionDefinition | null>(null);
  const [selectedSlice, setSelectedSlice] = useState<OptionDefinition | null>(
    null,
  );
  const [schedule, setSchedule] = useState<Schedule>(defaultSchedule);
  const { addNotification } = useNotificationContext();
  const [selectedFrequency, setSelectedFrequency] =
    useState<FrequencyOption | null>(null);

  const createTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.createTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  // Generates a task name based on scenario and slice name
  const generatedName = useMemo(() => {
    if (selectedSlice?.label && selectedScenario?.label) {
      return `${selectedSlice.label}_${selectedScenario.label}`.replace(
        /\s+/g,
        '',
      );
    }
    return '';
  }, [selectedSlice, selectedScenario]);

  const determineFrequency = useCallback(
    (name: string): FrequencyOption | null => {
      const sliceName = name.toUpperCase();
      if (sliceName.includes(Frequency.DAILY)) {
        return Frequency.DAILY;
      }
      if (sliceName.includes(Frequency.MONTHLY)) {
        return Frequency.MONTHLY;
      }
      return null;
    },
    [],
  );

  // Update frequency based on generated task name
  useEffect(() => {
    if (schedule.enable && generatedName) {
      const frequency = determineFrequency(generatedName);
      setSelectedFrequency(frequency);
    } else {
      setSelectedFrequency(null);
    }
  }, [schedule.enable, generatedName, determineFrequency]);

  const getSchedule = (schedule: Schedule) => ({
    workdayStart: Number(schedule.workdayStart?.value),
    workdayEnd: Number(schedule.workdayEnd?.value),
    scheduleTime:
      schedule.time?.value &&
      convertTime(schedule.time.value, getTimezoneOffset()),
  });

  const handleCancel = useCallback(() => {
    startTransition(() => navigate('/'));
  }, [navigate]);

  const handleScenarioChange = useCallback((event: SelectChangeEvent) => {
    setSelectedScenario(event.detail.selectedOption as OptionDefinition);
  }, []);

  const handleSliceChange = useCallback((event: SelectChangeEvent) => {
    setSelectedSlice(event.detail.selectedOption as OptionDefinition);
  }, []);

  const handleSubmit = async () => {
    try {
      const taskParams = {
        name: generatedName,
        scenario: selectedScenario?.value,
        slices: selectedSlice?.value ? [selectedSlice.value] : undefined,
        environment: Environment.PRODUCTION,
        methodology: Methodology.CF_ALLOCATION,
        business: undefined,
        region: undefined,
      };

      const slicedAllocationTaskInput = getSlicedAllocationTaskInput(
        taskParams.scenario,
        taskParams.slices,
        taskParams.environment,
        taskParams.methodology,
        taskParams.business,
        taskParams.region,
      );

      const createTaskRequest = {
        name: generatedName,
        // Todo : Create description in UI and use it
        // Ref SIM :https://issues.amazon.com/issues/P182436770
        description: 'CF allocation task',
        input: slicedAllocationTaskInput,
        schedule: schedule.enable ? getSchedule(schedule) : undefined,
      };

      await createTask.mutateAsync(createTaskRequest);
      addNotification({
        type: 'success',
        content: t(Notification.allocation.create.success),
      });

      startTransition(() => navigate('/cfallocation/manage'));
    } catch (error: any) {
      addNotification({
        type: 'error',
        header: t(Notification.allocation.create.error),
        content: error?.message,
      });
    }
  };

  return (
    <div data-testid="create-cf-allocation-page">
      <SpaceBetween size="l">
        <FormSection
          selectedScenario={selectedScenario}
          selectedSlice={selectedSlice}
          generatedName={generatedName}
          onScenarioChange={handleScenarioChange}
          onSliceChange={handleSliceChange}
        />
        <ScheduleSection
          schedule={schedule}
          selectedFrequency={selectedFrequency}
          selectedSlice={!!selectedSlice}
          selectedScenario={!!selectedScenario}
          onScheduleChange={setSchedule}
        />
        <Box float="right">
          <SpaceBetween direction="horizontal" size="m">
            <Button data-testid="cancel" onClick={handleCancel}>
              {t('cancel')}
            </Button>
            <Button
              data-testid="create"
              variant="primary"
              disabled={!selectedScenario || !selectedSlice}
              onClick={handleSubmit}
            >
              {t('create')}
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </div>
  );
};

export default CreateCFAllocationPage;
