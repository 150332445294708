import React, { lazy, startTransition, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@cloudscape-design/components/button';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { columnDefinitions } from './config/manage-allocation-table';
import { filteringProperties } from './config/manage-allocation-filter-property';
import { Table } from 'src/common/components/Table';
import { getContentDisplayOptions } from 'src/common/utils/table';
import QueryKey from 'src/api/QueryKey';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';
import { TaskExecutionItem } from 'src/common/types/TaskExecutionItem';
import { getTaskExecutionItem } from 'src/common/utils/task';

const AllocationActionModal = lazy(() => import('./AllocationActionModal'));
const AllocationValidationModal = lazy(
  () => import('./AllocationValidationModal'),
);

const ExecutionDetailsTab = () => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const columnDef = useMemo(() => columnDefinitions(t), [t]);
  const sortingCol = columnDef.find((def) => def.id === 'time');

  const [actionModal, setActionModal] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<TaskExecutionItem[]>([]);

  const generateClosetMonthStringForMultiPeriodAllocation = (
    execution: TaskExecutionItem,
  ): string => {
    if (execution.datasetStartPeriod === execution.datasetEndPeriod) {
      return execution.datasetStartPeriod!;
    }
    return `${execution.datasetStartPeriod} - ${execution.datasetEndPeriod}`;
  };

  const executions = useQuery({
    queryKey: [QueryKey.ListTaskExecutions, taskId],
    queryFn: async () => {
      const listExecutionResponse =
        await TaskSchedulingServiceApi.listTaskExecutions({
          taskId,
        });

      return listExecutionResponse.executions?.map((execution) => {
        if (!execution.closeMonth) {
          return {
            ...execution,
            closeMonth:
              generateClosetMonthStringForMultiPeriodAllocation(execution),
          };
        }
        return execution;
      });
    },
  });

  const toggleValidationModal = () => {
    startTransition(() => setValidationModal(!validationModal));
  };

  const toggleActionModal = () => {
    startTransition(() => setActionModal(!actionModal));
  };

  const handleAction = () => {
    toggleValidationModal();
    toggleActionModal();
  };

  const executionItems: TaskExecutionItem[] = useMemo(
    () => getTaskExecutionItem(executions.data),
    [executions.data],
  );

  return (
    <div data-testid="execution-details-page">
      {actionModal && (
        <AllocationActionModal
          task={selectedItems[0]}
          visible={actionModal}
          onClose={toggleActionModal}
        />
      )}
      {validationModal && (
        <AllocationValidationModal
          visible={validationModal}
          onAction={handleAction}
          onClose={toggleValidationModal}
          item={selectedItems[0]}
        />
      )}
      <Table<TaskExecutionItem>
        trackBy="executionId"
        testid="execution-details-table"
        data={executionItems}
        columnDefinitions={columnDef}
        pagination={true}
        preferences={true}
        contentDisplayOptions={getContentDisplayOptions(columnDef)}
        empty={{
          title: t('empty_execution_details'),
          description: t('empty_execution_details_description'),
        }}
        filteringProperties={filteringProperties(t)}
        header={t('execution_details')}
        onSelectionChange={setSelectedItems}
        selectedItems={selectedItems}
        selectionType="single"
        sortingState={
          sortingCol && {
            sortingColumn: sortingCol,
            isDescending: true,
          }
        }
        buttons={
          <Button
            data-testid="view-details"
            variant="primary"
            onClick={toggleValidationModal}
            disabled={!selectedItems.length}
          >
            {t('view_details')}
          </Button>
        }
      />
    </div>
  );
};

export default ExecutionDetailsTab;
